import $ from 'jquery';
window.jQuery = $;
window.$ = $;

export default function modal() {
  $('#videoModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var video_data = button.data('video') // Extract info from data-* attributes
    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this)
    modal.find('.modal-body source').attr("src", video_data);

    var video = modal.find('.modal-body video');
    video[0].load();
    video[0].play();
  })

  $('#videoModal').on('hide.bs.modal', function (event) {
    var modal = $(this)

    var video = modal.find('.modal-body video');
    video[0].pause();
    modal.find('.modal-body source').attr("src", '');

  });
}