import jQuery from "jquery";
import { carouselPrimary, carouselSecondary } from "./carousel_as_nav_for";
import modal from "./modal";
import tab from "bootstrap";
import initMap from "./initMap";
import floatingMenuObserver from "./floatingMenuObserver";
import readMoreObserver from "./readMoreObserver";
import loadMoreObserver from "./loadMoreObserver";

// Make jQuery globally available
// Should work in webpack as well.
window.$ = window.jQuery = jQuery;

document.onreadystatechange = function () {
  switch (document.readyState) {
    case "loading":
      // Das Dokument wird noch geladen.
      //console.log('loading');
      break;
    case "interactive":
      // Das Dokument wurde geladen. Wir können nun die DOM-Elemente ansprechen.
      //console.log('interactive');
      break;
    case "complete":
      // Die Seite ist komplett geladen.
      console.log('complete');
      modal();
      floatingMenuObserver();
      readMoreObserver();
      loadMoreObserver();
      //initMap();
      carouselPrimary();
      carouselSecondary();
      break;
  }
}


$('#v-pills-floatingmenu a').on('click', function (e) {
  e.preventDefault()
  $(this).tab('show')
});

$('.panel__action button').on('click', function (e) {
  e.preventDefault();
  $(this).closest(".menu__panel").removeClass('active');
  $("#v-pills-floatingmenu .nav-link.active").removeClass('active');
});


