import Flickity from "flickity";
import * as asNavFor from "flickity-as-nav-for";
import { dataCarousel } from "./theme-utils/data-selector";


function carouselPrimary() {
    // Should be reviewed after Pagebuilder Element rewrite
    // If its not working then the direct children of carousel__item is
    // not a .division class
    
    var images = document.querySelectorAll('[data-carousel-animation] .carousel__item .division');
    //console.log(images)
    let carousel = dataCarousel('flickityPrimary');
    if (carousel) {
        let f = new Flickity(carousel, {
            setGallerySize: false,
            cellAlign: 'left',
            contain: true,
            pageDots: false,
            prevNextButtons: false,
            wrapAround: true,
            asNavFor: dataCarousel('flickitySecondary')
        });
        // get transform property
        var docStyle = document.documentElement.style;
        var transformProp = typeof docStyle.transform == 'string' ?
            'transform' : 'WebkitTransform';

        f.on('scroll', function () {
            f.slides.forEach(function (slide, i) {
                var image = images[i];
                // console.log(image)
                var x = (slide.target + f.x) * -1 / 1.5;
                image.style[transformProp] = 'translateX(' + x + 'px)';
                //  console.log(image.style)
            });
        });
    }
}



function carouselSecondary() {
    let carousel = dataCarousel('flickitySecondary');
    if (carousel) {
        let f = new Flickity(carousel, {
            setGallerySize: true,
            prevNextButtons: false,
            cellAlign: 'left',
            contain: true,
            pageDots: false,
            wrapAround: true,
            arrowShape: {
                x0: 10,
                x1: 60, y1: 50,
                x2: 70, y2: 40,
                x3: 30
            },

        });
        // previous
        var previousButton = document.querySelector('.button--previous');
        var nextButton = document.querySelector('.button--next');

        previousButton.addEventListener('click', function () {
            f.previous();
        });
        // next
        var nextButton = document.querySelector('.button--next');
        nextButton.addEventListener('click', function () {
            f.next();
        });

        let current_item = document.querySelector('.carousel__item.is-selected .circle')
        let current_color = current_item.dataset.color;
        previousButton.classList.add(`${current_color}`)
        nextButton.classList.add(`${current_color}`)


        f.on('select', (index) => {

            previousButton.classList.remove(`${current_color}`)

            let selected_item = document.querySelector('.carousel__item.is-selected .circle')
            let selected_color = selected_item.dataset.color;

            previousButton.classList.remove(`${current_color}`)
            previousButton.classList.add(`${selected_color}`)
            nextButton.classList.remove(`${current_color}`)
            nextButton.classList.add(`${selected_color}`)

            current_color = selected_color

        });
    }
}

export {
    carouselPrimary,
    carouselSecondary
}