
function floatingMenuObserver(){

// Nav Observer
// The DOM node to observe
const target = document.getElementById("nav-toggle");

// Callback function when changes occurs
function callback(mutationRecord, observer) {
  if (document.querySelector(".mobile-floating").classList.contains('mobile-floating--open')){
    document.querySelector(".mobile-floating").classList.remove('mobile-floating--open');
    document.querySelector(".verso-nav-sticky").classList.remove('verso-nav-sticky--open');
    document.querySelector("body").classList.remove('verso-nav-sticky-is-open');
  } else {
    document.querySelector(".mobile-floating").classList.add('mobile-floating--open');
    document.querySelector(".verso-nav-sticky").classList.add('verso-nav-sticky--open');
    document.querySelector("body").classList.add('verso-nav-sticky-is-open');
  }
}

// Create a new instance of MutationObserver with callback in params
const observer = new MutationObserver(callback);

// Setup config
const config = {
  attributes: true,
  attributeOldValue: true,
  attributeFilter: ["class"]
};

observer.observe(target, config);

}

export default floatingMenuObserver;