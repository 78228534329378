/**
 * 
 * @param {*} selector 
 */

var dataJs = function (selector) {
    return document.querySelectorAll('[data-js=' + selector + ']');
};
/**
 * 
 * @param {*} selector 
 */
var dataAnimation = function (selector) {
    return document.querySelectorAll('[data-animation=' + selector + ']');
};

var dataAnchor = function (selector) {
    return document.querySelectorAll('[data-anchor=' + selector + ']');
};

var dataCarousel = function (selector) {
    return document.querySelector('[data-carousel=' + selector + ']');
};

var dataToggle = function (selector) {
    return document.querySelector('[data-toggle=' + selector + ']');
};

var getHash = function () {
    var hash = window.location.hash.replace('#', '');
    if (hash != '') {
        console.log(hash);
        return hash;
    } else {
        return null;
    }
}
export {
    dataJs,
    dataAnimation,
    dataAnchor,
    dataCarousel,
    dataToggle,
    getHash
};