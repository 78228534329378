
import $ from 'jquery';
window.jQuery = $;
window.$ = $;


export default function loadMoreObserver() {
  var $loadMoreContainer = $('.verso-recent-posts-load-more--container');
  var $loadMoreButton = $loadMoreContainer.find('.verso-recent-posts-load-more--button');
  var $loadLessButton = $loadMoreContainer.find('.verso-recent-posts-load-less--button');
  var $posts = $loadMoreContainer.find('.verso-recent-posts-load-more--posts').children();
 
  $loadMoreButton.on('click', function(){
    $loadMoreContainer.addClass('verso-recent-posts-load-more--open');
  });

  $loadLessButton.on('click', function(){
   $loadMoreContainer.removeClass('verso-recent-posts-load-more--open');
  });
}