import $ from 'jquery';
window.jQuery = $;
window.$ = $;

export default function readMoreObserver() {
  var $readMoreContainer = $('.read-more--text');

  function init() {
    if ($readMoreContainer.length > 0) {
      $readMoreContainer.each(function () {
        var $this = $(this);
        var hiddenParagraphs;

        $this.append(
          '<div class="read-more--button btn-outline-white btn">Mehr lesen</div>'
        );
        $this
          .find('p')
          .not(':first-child')
          .slideUp(function () {
            hiddenParagraphs = $this.find('p:hidden');
          });
      });

      var $readMoreButton = $('.read-more--button');
      $readMoreButton.on('click', function () {
        console.log($(this));
        $(this)
          .queue(function () {
            $(this).prev().find('p:hidden').slideDown();
          })
          .html('Weniger anzeigen')
          .addClass('read-more--close-button')
          .removeClass('read-more--button');

        $('.read-more--close-button').on('click', function () {
          $('.read-more--button').remove();
          $('.read-more--close-button').remove();
          init();
        });
      });
    }
  }
  init();
}
